<script setup>
    import PrimevueDropdown from 'primevue/dropdown';
    import PrimevueInputText from 'primevue/inputtext';
    import PrimevueMultiSelect from 'primevue/multiselect';

    // Props
    const props = defineProps({
        class: {
            type: [String, Object],
            default: '',
        },
        style: {
            type: [String, Object],
            default: '',
        },
        modelValue: {
            default: null,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        options: {
            type: Array,
            required: true,
        },
        optionLabel: {
            type: String,
            default: null,
        },
        optionValue: {
            type: String,
            default: null,
        },
        optionGroupLabel: {
            type: String,
            default: null,
        },
        optionGroupChildren: {
            type: String,
            default: null,
        },
        placeholder: {
            type: String,
            default: null,
        },
        virtualScrollerOptions: {
            type: Object,
            default: null,
        },
        showOtherValue: {
            type: String,
            default: null,
        },
        showLabel: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        appendTo: {
            type: String,
            default: 'body',
        },
        clearable: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        filter: {
            type: Boolean,
            default: false,
        },
        filterFields: {
            type: Array,
            default: null,
        },
        autoFilterFocus: {
            type: Boolean,
            default: false,
        },
        rules: {
            type: String,
            default: '',
        },
        root: {
            type: Boolean,
            default: false,
        },
        exclude: {
            type: Array,
            default: () => [],
        },
        id: {
            type: String,
            default: null,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        pt: {
            type: Object,
            default: {},
        },
    });

    // Emits
    const emit = defineEmits(['change', 'filter', 'update:modelValue']);

    // Refs
    const model = ref(props.modelValue);
    const other = ref(null);

    if (!props.multiple) {
        model.value = [props.modelValue];
    }

    const options = computed(() => {
        if (!props.options) {
            return [];
        }

        return props.options.filter((option) => {
            return !props.exclude.includes(option[props.optionValue]);
        });
    });

    // Should we show the "Other" input text ?
    // If prop is true, and if array contain "other" value or if model is "other"
    const showOther = computed(() => {
        return (
            props.showOtherValue &&
            model.value &&
            ((props.multiple && model.value.includes(props.showOtherValue)) ||
                model.value[0] == props.showOtherValue)
        );
    });

    // Update model value when updating from "Other" field
    const updateOther = (e) => {
        if (props.multiple && showOther.value) {
            let temp = new Array(...model.value);
            temp = temp.filter((item) => item != props.showOtherValue);
            temp.push(other.value);

            return emit('update:modelValue', temp);
        }

        emit('update:modelValue', e[0]);
    };

    /**
     * If the value is not in options, set the value to "other"
     * and set the value in other variable
     * @param {String} value
     */
    const setOther = (value) => {
        if (!props.options.find((option) => option[props.optionValue] === value)) {
            other.value = value;
            value = props.showOtherValue;
        }

        return value;
    };

    /**
     * Merge props classes with errorClass from validator
     * @param {String} errorClass
     * @returns {Object}
     */
    const getClasses = (errorClass) => {
        let classes = {};

        if (typeof props.class == 'string') {
            classes[props.class] = true;
        } else {
            classes = props.class;
        }

        if (errorClass) {
            classes[errorClass] = true;
        }

        return classes;
    };

    const componentId = computed(() => {
        return props.id || slugify(props.placeholder);
    });

    const isValid = computed(() => {
        let comparisonValue = props.multiple ? undefined : null;
        return model.value && model.value[0] !== comparisonValue ? 'ok' : '';
    });

    // Update model value when updating from parent
    watch(
        () => props.modelValue,
        () => {
            if (!props.showOtherValue) {
                model.value = !props.multiple ? [props.modelValue] : props.modelValue;
            }
        }
    );

    // Set the other value if model value is not in options
    if (props.showOtherValue && model.value && model.value[0]) {
        for (let key in model.value) {
            model.value[key] = setOther(model.value[key]);
        }
    }
</script>

<template>
    <Field
        :id="componentId"
        :name="props.placeholder"
        :showLabel="props.showLabel"
        :value="isValid"
        :rules="props.rules"
        :root="props.root"
        v-slot="{ change, errorClass }"
    >
        <!-- Append dropdown to the container only if is not as root -->
        <PrimevueDropdown
            v-if="!props.multiple"
            v-model="model[0]"
            :class="getClasses(errorClass)"
            :options="options"
            :optionLabel="props.optionLabel"
            :optionValue="props.optionValue"
            :optionGroupLabel="props.optionGroupLabel"
            :optionGroupChildren="props.optionGroupChildren"
            :placeholder="props.placeholder"
            :virtualScrollerOptions="props.virtualScrollerOptions"
            :required="props.required"
            :appendTo="props.appendTo"
            :disabled="props.disabled"
            :filter="props.filter"
            :filterFields="props.filterFields"
            :autoFilterFocus="props.autoFilterFocus"
            :showClear="props.clearable"
            :style="props.style"
            :loading="props.loading"
            :pt="props.pt"
            @update:modelValue="emit('update:modelValue', $event)"
            @filter="emit('filter', $event)"
            @change="
                change($event);
                emit('change', $event);
            "
        >
            <template v-if="$slots.option" #option="{ option }">
                <slot name="option" :option="option" />
            </template>

            <template v-if="$slots.value" #value="{ value }">
                <slot name="value" :value="value" />
            </template>
        </PrimevueDropdown>

        <!-- affiche d'un select multiple -->
        <PrimevueMultiSelect
            v-else
            display="chip"
            v-model="model"
            :class="getClasses(errorClass)"
            :options="options"
            :optionLabel="props.optionLabel"
            :optionValue="props.optionValue"
            :optionGroupLabel="props.optionGroupLabel"
            :optionGroupChildren="props.optionGroupChildren"
            :placeholder="props.placeholder"
            :virtualScrollerOptions="props.virtualScrollerOptions"
            :required="props.required"
            :appendTo="props.appendTo"
            :disabled="props.disabled"
            :filter="props.filter"
            :filterFields="props.filterFields"
            :autoFilterFocus="props.autoFilterFocus"
            :style="props.style"
            :loading="props.loading"
            :pt="props.pt"
            @update:modelValue="emit('update:modelValue', $event)"
            @filter="emit('filter', $event)"
            @change="emit('change', $event)"
        >
            <template v-if="$slots.option" #option="{ option }">
                <slot name="option" :option="option" />
            </template>

            <template v-if="$slots.chip" #chip="{ value }">
                <slot name="chip" :option="value" />
            </template>

            <template v-if="$slots.value" #value="{ value }">
                <slot name="value" :value="value" />
            </template>
        </PrimevueMultiSelect>

        <PrimevueInputText
            v-if="showOther"
            v-model="other"
            :placeholder="$t('Specify...')"
            :required="props.required"
            @update:modelValue="updateOther"
        />
    </Field>
</template>
